import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  InputLabel,
  Modal,
  Divider,
  Button,
} from "@mui/material";

import {
  StyledAccountContent,
  StyledAuthButton,
  StyledAuthInputFields,
} from "../micro/micro.styled";

import CloseModalIcon from "../../assets/CloseModalIcon.svg";
import { api } from "../../api/base";
import { useSnackBar } from "../../context/SnackBar";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { analyticsEvent } from "../../redux/actions/analytics";
import { AxiosResponse } from "axios";
import {
  setForgotPasswordModalOpen,
  setLoginModalOpen,
  setUserDataEmail,
} from "../../redux/actions/app";

import TickCircle from "../../assets/tick-circle.svg";
import CloseCircle from "../../assets/close-circle.svg";
import { decodeToken } from "react-jwt";

const LoginModal = (): JSX.Element => {
  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();
  const dispatch = useDispatch();
  const loginModalOpen = useSelector((state: any) => state.app.loginModalOpen);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isPurchase) {
      showSnackBar(
        "Log in to complete your purchase",
        "info",
        "",
        5000,
        true,
      );
    }
  }, [searchParams])

  const handleClose = () => {
    dispatch(setLoginModalOpen(false));
  };

  const [userData, setUserData] = useState<{ email: string; password: string }>(
    {
      email: "",
      password: "",
    },
  );

  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const isPurchase = searchParams.get('purchase');

  const openForgotPasswordModal = (): void => {
    dispatch(setForgotPasswordModalOpen(true));
    dispatch(setLoginModalOpen(false));
  };

  const validateForm = async (): Promise<void> => {
    if (isValidEmail(userData.email) && isValidPassword(userData.password)) {
      await loginUser();
    }
  };

  const isValidPassword = (password: string): boolean => {
    setShowPasswordError(false);
    if (!password) {
      setShowPasswordError(true);
      setPasswordError("This is a required field.");
      return false;
    }
    return true;
  };

  const isValidEmail = (email: string): boolean => {
    setShowEmailError(false);
    if (!email) {
      setShowEmailError(true);
      setEmailError("This is a required field.");
      return false;
    }
    if (!validateEmailFormat(userData.email)) {
      setShowEmailError(true);
      setEmailError("Please enter a valid email.");
      return false;
    }
    return true;
  };

  const validateEmailFormat = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
  };

  const loginUser = async (): Promise<void> => {
    setLoading(true);
    try {
      const { data }: AxiosResponse<any, any> = await api.post("auth/login", {
        email: userData.email?.toLowerCase(),
        password: userData.password,
        platform: "web",
        sessionId: localStorage.getItem("sessionId"),
        deviceId: localStorage.getItem("device_id"),
      });
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      const token: any = data.access_token;
      const decoded: any = decodeToken(token);
      localStorage.setItem("user_id", decoded.userId);
      localStorage.removeItem("onboarding_in_progress");
      showSnackBar(
        "Successfully logged in!",
        "success",
        "center",
        2000,
        false,
        "auto",
        "95px",
      );
      if (isPurchase) {
        try {
          const { data }: AxiosResponse<any, any> = await api.post(
            "chargebee/checkout-hardware",
            {
              redirect_url: `${process.env.REACT_APP_URL}/account/upgrade-success?plan=hardware`,
              email: userData.email.toLowerCase(),
            },
          );
          console.log('checkout')
          navigate(`/account/checkout`, {
            state: {
              url: data.hosted_page.url,
              additionalText: "Hardware - $300",
              title: "REC-1",
              isFromExistingUser: true,
            }
          });
          console.log('checkout')
          return;
        } catch (err) {
          console.error(err)
          setLoading(false);
        }
        handleClose();
        return;
      } else {
        const currentPath = window.location.pathname;
        if (currentPath === '/daw/login') {
          window.location.reload();
        } else {
          navigate(`/account${window.location.search}`);
        }
        handleClose();
        dispatch(
          analyticsEvent("EVENT", "Web: Logged In", {
            plan: decoded.plan || "free",
            status: decoded.status || "inactive",
          }),
        );
      }
    } catch (err: any) {
      if (err.response.status === 401) {
        dispatch(
          analyticsEvent("EVENT", "Web: Login Failed", {
            email: userData.email.toLowerCase(),
          }),
        );
        console.log(err.response);
        if (err.response.data === "Password is incorrect") {
          setShowPasswordError(true);
          setPasswordError("Password is incorrect.");
        } else {
          setShowEmailError(true);
          setEmailError("Email is incorrect.");
        }
      } else {
        setShowEmailError(true);
        setEmailError(err?.response?.data?.msg || err?.response?.data);
      }
    }
    setLoading(false);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === "email") {
      const email = e.target.value.toLowerCase();
      setEmailError("");
      setShowEmailError(false);
      dispatch(setUserDataEmail(email));
      setUserData({
        ...userData,
        email,
      });
    } else {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value,
      });
      setShowPasswordError(false);
    }
  };

  useEffect(() => {
    if (!loginModalOpen) {
      setEmailError("");
      setPasswordError("");
      setShowEmailError(false);
      setShowPasswordError(false);
      setUserData({
        email: "",
        password: "",
      });
    }
  }, [loginModalOpen]);

  const handleSignUp = (): void => {
    dispatch(setLoginModalOpen(false));
    const referrer = localStorage.getItem("referrer");
    dispatch(
      analyticsEvent("EVENT", "Get Density Clicked", {
        page: "Login",
        flow: referrer && referrer !== "Density" ? "invited" : "primary",
      }),
    );
    navigate("/onboarding/0/density38");
  };

  const currentPath = window.location.pathname;
  const showCloseButton = !currentPath.includes("/daw/login");

  return (
    <Modal
      open={loginModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(30px)",
      }}
    >
      <Box
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        height={"100%"}
        maxWidth={"427px"}
        width={"100%"}
        mx={"auto"}
      >
        <StyledAccountContent
          sx={{
            width: "100%",
            gap: "6px",
            backgroundColor: "#141519",
            border: "none",
          }}
          style={{ padding: "32px 0" }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={0}
            px={"32px"}
          >
            <Typography
              variant={"h2"}
              sx={{ mx: "auto", mb: 0 }}
              color="#C1C5DD"
            >
              Login
            </Typography>
            {showCloseButton && (
            <button
              onClick={handleClose}
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                padding: "0",
                height: "20px",
                width: "20px",
              }}
            >
              <img
                style={{
                  maxWidth: "20px",
                  objectFit: "contain",
                }}
                src={CloseModalIcon}
                alt="close modal"
              />
            </button>
          )}
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ position: "relative", px: "32px" }}>
              <InputLabel
                sx={{ mb: "20px", fontSize: "11px", color: "#dcdee4" }}
                htmlFor="email"
              >
                Email
              </InputLabel>
              <Box position={"relative"}>
                <StyledAuthInputFields
                  value={userData.email}
                  onChange={onChangeHandler}
                  type={"email"}
                  name={"email"}
                  autoComplete="off"
                  placeholder={""}
                  sx={{
                    width: "100%",
                    backgroundColor: "#100f12",
                    "& .Mui-focused": {
                      boxShadow: "none",
                      caretColor: "#575F9E !important",
                      outline: showEmailError
                        ? "1px solid transparent"
                        : "1px solid #575F9E",
                    },
                    "&.Mui-focused": {
                      boxShadow: "none",
                      caretColor: "#575F9E !important",
                      outline: showEmailError
                        ? "1px solid transparent"
                        : "1px solid #575F9E",
                    },
                  }}
                  error={emailError !== ""}
                />
                {userData.email && !showEmailError && (
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      objectFit: "contain",
                      position: "absolute",
                      top: "50%",
                      right: "16px",
                      transform: "translateY(-50%)",
                    }}
                    src={TickCircle}
                    alt=""
                  />
                )}
                {showEmailError && (
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      objectFit: "contain",
                      position: "absolute",
                      top: "50%",
                      right: "16px",
                      transform: "translateY(-50%)",
                    }}
                    src={CloseCircle}
                    alt=""
                  />
                )}
              </Box>
              {showEmailError && (
                <Typography fontSize={12} color={"error"} variant={"body1"}>
                  {emailError}
                </Typography>
              )}
            </Box>

            <Box sx={{ position: "relative", px: "32px" }}>
              <InputLabel
                sx={{ mb: "20px", fontSize: "11px", color: "#dcdee4" }}
                htmlFor="password"
              >
                Password
              </InputLabel>
              <Box position={"relative"}>
                <StyledAuthInputFields
                  value={userData.password}
                  onChange={onChangeHandler}
                  type={"password"}
                  name={"password"}
                  autoComplete="off"
                  placeholder={""}
                  sx={{
                    width: "100%",
                    backgroundColor: "#100f12",
                    "& .Mui-focused": {
                      boxShadow: "none",
                      caretColor: "#575F9E !important",
                      outline: showPasswordError
                        ? "1px solid transparent"
                        : "1px solid #575F9E",
                    },
                    "&.Mui-focused": {
                      boxShadow: "none",
                      caretColor: "#575F9E !important",
                      outline: showPasswordError
                        ? "1px solid transparent"
                        : "1px solid #575F9E",
                    },
                  }}
                  error={passwordError !== ""}
                />
                {userData.password && !showPasswordError && (
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      objectFit: "contain",
                      position: "absolute",
                      top: "50%",
                      right: "16px",
                      transform: "translateY(-50%)",
                    }}
                    src={TickCircle}
                    alt=""
                  />
                )}
                {showPasswordError && (
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      objectFit: "contain",
                      position: "absolute",
                      top: "50%",
                      right: "16px",
                      transform: "translateY(-50%)",
                    }}
                    src={CloseCircle}
                    alt=""
                  />
                )}
              </Box>
              {showPasswordError && (
                <Typography fontSize={12} color={"error"} variant={"body1"}>
                  {passwordError}
                </Typography>
              )}
            </Box>
            <Box sx={{ px: "32px", mt: "24px" }}>
              <Button
                sx={{ width: "100%", margin: "0 auto" }}
                onClick={validateForm}
                variant={"contained"}
                disabled={loading}
              >
                {loading ? "Loading..." : "Log in"}
              </Button>

              <Typography
                my={3}
                variant={"body1"}
                onClick={openForgotPasswordModal}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#737BAE",
                  cursor: "pointer",
                  fontSize: "12px",
                  letterSpacing: "0.1px",
                }}
              >
                Forgot password
              </Typography>
            </Box>

            <Divider />

            <Typography
              mt={3}
              variant={"body1"}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "rgba(252, 252, 252, 0.5)",
                fontSize: "12px",
                letterSpacing: "0.1px",
              }}
            >
              New to Density?{" "}
              <span
                style={{ color: "#737BAE", cursor: "pointer" }}
                onClick={handleSignUp}
              >
                Sign Up
              </span>
            </Typography>
          </Box>
        </StyledAccountContent>
      </Box>
    </Modal>
  );
};

export default LoginModal;
