import { Link } from "@mui/material";
import { InfoPanelProps } from "./types";
import { useNavigate } from "react-router-dom";

function InfoPanel({
  data,
  children,
  className = "",
}: {
  data: InfoPanelProps;
  children: React.ReactNode;
  className?: string;
}) {
  const { title, subtitle, description, heading, url } = data;

  const navigate = useNavigate();
  return (
    <div className={`flex flex-col items-center justify-center text-center text-primary-purple ${className}`}>
      {title && (
        <div className="font-['PPRightGrotesk-Bold'] text-[34px] font-black leading-[38.85px] mb-[4.6875rem]">
          {title}
          {url && <div className="text-center mb-10">
            <Link
              onClick={() => {
                navigate(url);
                window.scrollTo(0, 0);

              }}
              variant="body2"
              className="!text-primary-purple text-left font-inter text-base text-base font-medium leading-6 leading-6 tracking-wider cursor-pointer"
              sx={{
                fontFamily: "Inter !important",
                fontSize: "14px !important",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.0125rem",
                textAlign: "left !important",
              }}
              target="_blank"
            >
              Learn more
            </Link>
          </div>}
        </div>
      )}
      <div className="md:py-8 md:pl-8">{children}</div>
      {heading && (<div className="font-['PPRightGrotesk-Bold'] text-[24px] font-black leading-[27.42px] text-center">
        {heading}
      </div>
      )}
      {subtitle && (<div className="max-w-[30ch] font-['Inter'] text-[12px] font-medium leading-[18px] tracking-[0.1px] !text-base-gray ">
        {subtitle}
      </div>
      )}
      {description && (
        <div className="font-['Inter'] text-[12px] font-medium leading-[18px] tracking-[0.1px] !text-base-gray pt-4">
          {description}
        </div>
      )}
    </div>
  );
}

export default InfoPanel;
