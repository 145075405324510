export enum DeviceType {
    Mobile = "mobile",
    Tablet = "tablet",
    Desktop = "desktop",
  }
  
 export  enum OS {
    Android = "android",
    IOS = "ios",
    Windows = "windows",
    MacOS = "macos",
    Linux = "linux",
    Unknown = "unknown",
  }

  export enum GOOGLE_CONVERSION_EVENTS {
    REGISTERED_MAC = "Registered Mac",
    REGISTERED_IOS = "Registered iOS",
    REGISTERED_WINDOWS = "Registered Windows (non Mac)",
    REGISTERED_ANDROID = "Registered Android",
  
    DOWNLOAD_MAC = "Download Mac",
    DOWNLOAD_WINDOWS = "Download Windows",
    DOWNLOAD_IOS = "Download iOS",
    DOWNLOAD_ANDROID = "Download Android",
  }