import React, { useContext, useState } from 'react';
import axios from 'axios';
import { UserProps } from '../types/auth';
import { api } from '../api/base';

type UserContextActions = {
  plan: {
    planId: string,
    planName: string,
  };
  user: UserProps,
  otp: string,
  setPlan: ({ planId, planName }: { planId: string, planName: string }) => void;
  setUser: (user: UserProps) => void;
  fetchOtp: () => Promise<void>;
  setOtp: (otp: string) => void;
};

interface UserContextProviderActions {
  children: React.ReactNode;
}

const UserContext = React.createContext({ } as UserContextActions);

const UserProvider: React.FC<UserContextProviderActions>  = ({ children }: any) => {
  const [plan, setPlan] = useState<{ planId: string, planName: string }>({
    planId: '',
    planName: '',
  });
  const [user, setUser] = useState<UserProps>({} as UserProps);
  const [otp, setOtp] = useState<string>('');

  const fetchOtp = async () => {
    try {
      const response = await api.get('/auth/otp');
      setOtp(response.data.otp);
    } catch (error) {
      console.error('Error fetching OTP:', error);
    }
  };

  return (
    <UserContext.Provider value={{ setPlan, plan, user, setUser, otp, fetchOtp, setOtp }}>
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = (): UserContextActions => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within an UserProvider');
  }

  return context;
};

export { UserProvider, useUserContext };