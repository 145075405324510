import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  InputLabel,
  TextField,
  Modal,
  Divider,
} from "@mui/material";

import { useUserContext } from "../../context/UserContext";
import {
  StyledAccountContent,
  StyledAuthButton,
  StyledAuthInputFields,
} from "../../components/micro/micro.styled";

import CloseModalIcon from "../../assets/CloseModalIcon.svg";
import { api } from "../../api/base";
import { useSnackBar } from "../../context/SnackBar";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../redux/actions/analytics";

interface ChangeEmailPageProps {
  open: boolean;
  handleClose: () => void;
  setUser: (user: any) => void;
}

const ChangeEmailModal = ({
  open,
  handleClose,
  setUser,
}: ChangeEmailPageProps): JSX.Element => {
  const { showSnackBar } = useSnackBar();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const dispatch = useDispatch();

  const { user } = useUserContext();

  const validateEmailFormat = (_email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(_email.toLowerCase());
  };

  const isValidEmail = (_email: string): boolean => {
    if (!_email) {
      setEmailError("This is a required field.");
      return false;
    }
    if (!_email.includes("@")) {
      setEmailError("That's an invalid email. Missing an @ symbol..");
      return false;
    }
    if (!validateEmailFormat(_email)) {
      setEmailError("Please enter a valid email.");
      return false;
    }
    if (_email === user?.email) {
      setEmailError("Cannot be a previously used email.");
      return false;
    }
    return true;
  };

  const submitHandler = async () => {
    if (isValidEmail(email)) {
      try {
        const response = await api.post("auth/set-user-email", {
          userId: user?.id,
          email,
        });
        if (response.status === 200) {
          const updatedUser = {
            ...user,
            ...response.data.user,
          };
          dispatch(
            analyticsEvent("EVENT", "Profile: Changed Email", {
              email: updatedUser.email,
              old_email: user.email,
              user_properties: {
                email: updatedUser.email,
              },
            }),
          );
          handleClose();
          showSnackBar(
            "Email changed",
            "success",
            "center",
            2000,
            false,
            "auto",
          );
          setUser(updatedUser);
          window.location.reload();
        }
      } catch (err) {
        setEmailError("Email already exists.");
        console.log(err);
      }
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  useEffect(() => {
    if (!open) {
      setEmail("");
      setEmailError("");
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(3px)",
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      }}
    >
      <Box
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        height={"100%"}
        maxWidth={"420px"}
        width={"100%"}
        mx={"auto"}
      >
        <StyledAccountContent
          sx={{ width: "100%", gap: "16px", backgroundColor: "#141519" }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant={"h2"}>Change your email address</Typography>
            <button
              onClick={handleClose}
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                padding: "0",
                height: "20px",
                width: "20px",
              }}
            >
              <img
                style={{
                  maxWidth: "20px",
                  objectFit: "contain",
                }}
                src={CloseModalIcon}
                alt="close modal"
              />
            </button>
          </Box>
          <Typography
            alignItems={"center"}
            display={"flex"}
            justifyContent={""}
            variant="body1"
            fontSize={12}
          >
            Current email address:&nbsp;
            <span style={{ color: "#fcfcfc" }}>{user?.email}</span>
          </Typography>
          <Typography
            alignItems={"center"}
            display={"flex"}
            justifyContent={""}
            variant="body1"
            fontSize={12}
            lineHeight={"16px"}
          >
            Enter the new email address below. We will send the confirmation
            code to that email.
          </Typography>
          <Box sx={{ width: "100%" }}>
            <InputLabel
              sx={{
                fontSize: 11,
                fontFamily: "Inter",
                lineHeight: "16px",
                letterSpacing: "0.4px",
                color: "#DCDEE4",
                top: 0,
              }}
              htmlFor="email"
            >
              New Email Address
            </InputLabel>
            <StyledAuthInputFields
              value={email}
              onChange={handleEmailChange}
              type={"email"}
              name={"email"}
              sx={{
                backgroundColor: "#100f12",
                width: "100%",
                input: {
                  color: "rgba(252, 252, 252, 0.6)",
                },
                marginBottom: "7px",
              }}
              error={!!emailError}
            />
            {emailError && (
              <Typography fontSize={12} color={"error"} variant={"body1"}>
                {emailError}
              </Typography>
            )}
          </Box>
          <Divider
            sx={{
              width: "100%",
            }}
          />
          <Box display={"flex"} justifyContent={"center"}>
            <StyledAuthButton
              variant={"contained"}
              sx={{
                width: "100%",
                mx: "auto",
                marginBottom: "7px",
                marginTop: "10px",
              }}
              onClick={submitHandler}
            >
              Continue
            </StyledAuthButton>
          </Box>
        </StyledAccountContent>
      </Box>
    </Modal>
  );
};

export default ChangeEmailModal;
